/* eslint-disable import/order */

import { FooterSignup } from '#app/components/footer-signup.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { cn } from '#app/utils/misc.tsx'
import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'

export const meta: MetaFunction = () => [{ title: 'Tremolo' }]

const images = [
	{
		src: 'https://images.pexels.com/photos/17686015/pexels-photo-17686015/free-photo-of-two-street-musicians-playing-on-a-sea-waterfront.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
		clz: /*tw!*/ '',
	},
	{
		src: 'https://images.pexels.com/photos/6647840/pexels-photo-6647840.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
		clz: /*tw!*/ '',
	},
	{
		src: 'https://images.pexels.com/photos/8520468/pexels-photo-8520468.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
		clz: /*tw!*/ '',
	},
	{
		src: 'https://images.pexels.com/photos/7577169/pexels-photo-7577169.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
		clz: /*tw!*/ 'brightness-150 ',
	},
	{
		src: 'https://images.pexels.com/photos/8192113/pexels-photo-8192113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
		clz: /*tw!*/ '',
	},
	{
		src: 'https://images.pexels.com/photos/13907004/pexels-photo-13907004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
		clz: /*tw!*/ 'brightness-125 ',
	},
	{
		src: 'https://images.pexels.com/photos/7827275/pexels-photo-7827275.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
		clz: /*tw!*/ '',
	},
]

const benefits = [
	'Access to skilled college-aged instructors who can provide personalized guidance and instruction.',
	'Convenient scheduling options to fit lessons into busy student schedules.',
	'Diverse range of musical instruments and styles offered for learning and exploration.',
	'User-friendly interface that makes it easy to book lessons, track progress, and communicate with instructors.',
]

export default function Index() {
	return (
		<main className="h-full">
			<section className="relative overflow-x-clip">
				<div className="pointer-events-none absolute -bottom-40 -right-[47rem] h-[1175px] w-[830px] select-none rounded-[60px] bg-gradient-to-b from-secondary/0 to-secondary/40 opacity-30 md:-bottom-40 md:-right-[38rem] lg:-right-[32rem] 2xl:-bottom-44 2xl:-right-80 2xl:to-secondary/60 3xl:-right-36" />

				<div className="pointer-events-none absolute -left-[52rem] top-1/2 h-[470px] w-[978px] select-none rounded-[60px] bg-gradient-to-b from-secondary/0 to-secondary/60 opacity-30 md:-left-[40rem] md:h-[550px] lg:-left-[32rem] lg:h-[650px] 2xl:-left-80 2xl:h-[750px] 3xl:-left-20 3xl:h-[766px]" />

				<div className="pointer-events-none mx-auto flex min-h-[275px] w-full max-w-screen-4xl select-none items-center justify-center gap-4 overflow-x-clip pt-32 duration-1000 animate-in slide-in-from-bottom-8 md:min-h-[300px] md:gap-5 md:pt-40 lg:min-h-[400px] lg:gap-6 2xl:min-h-[500px] 3xl:min-h-[500px] 3xl:gap-8 3xl:pt-52 4xl:h-[700px] 4xl:overflow-x-visible">
					{images.map((img, idx) => (
						<div
							key={idx}
							className="relative aspect-[1/2] w-5/12 flex-shrink-0 overflow-hidden rounded-[7vmin] md:w-1/5 md:rounded-[5vmin] xl:w-[14%]"
						>
							<img
								className={cn('absolute h-full w-auto object-cover', img.clz)}
								src={img.src}
								alt=""
							/>
							<div className="absolute inset-0 rounded-[7vmin] bg-gradient-to-b from-primary to-primary opacity-35 ring-1 ring-inset ring-accent/50 md:rounded-[5vmin]" />
						</div>
					))}
				</div>
			</section>

			<section className="relative pt-52 xl:block">
				<div className="w-full max-w-screen-3xl xl:mx-auto">
					<div className="px-8 md:ml-20 lg:ml-28 xl:ml-40 3xl:mx-0 3xl:grid 3xl:w-1/2 3xl:place-items-center 3xl:px-0">
						<p className="font-display text-4xl font-extrabold leading-none tracking-wide md:max-w-[28rem] md:text-5xl lg:max-w-[32rem] lg:text-6xl xl:max-w-[620px] 2xl:text-7xl">
							We connect talented college-aged musicians with young students, to
							change the way music lessons are done.
						</p>
					</div>
				</div>
				<div className="relative min-h-[400px] items-center overflow-x-clip md:min-h-[720px] lg:min-h-[880px] 2xl:min-h-[1080px]">
					<div className="pointer-events-none absolute -left-52 top-1/2 aspect-[600/700] h-72 -translate-y-1/2 select-none rounded-[60px] bg-gradient-to-r from-secondary/0 to-secondary/60 opacity-30 md:-left-[18rem] md:h-[500px] lg:-left-[15rem] lg:h-[550px] 2xl:-left-72 2xl:h-[700px] 3xl:-left-12" />

					<div className="pointer-events-none absolute inset-y-0 -right-[15rem] w-[500px] select-none rounded-[60px] bg-gradient-to-t from-secondary/0 to-secondary/60 opacity-30 md:-right-[15rem] md:w-[700px] lg:-right-[15rem] lg:w-[800px] 2xl:-right-72 2xl:w-[1080px] 3xl:-right-12" />
				</div>
			</section>

			<section className="mx-auto -translate-y-24 px-8 pb-16 md:-translate-y-20 md:px-10 3xl:max-w-screen-2xl 3xl:px-0">
				<div className="pl-2 md:pl-10 lg:pl-16 2xl:pl-20 3xl:pl-14">
					<p className="bg-gradient-to-b from-primary to-primary-dark to-20% bg-clip-text font-display text-3xl leading-none text-transparent opacity-80 md:text-4xl md:leading-normal 2xl:text-5xl 2xl:leading-normal">
						Based out of the greater Seattle area
					</p>
					<p className="text-md text-pretty pt-2 font-display font-black leading-relaxed text-primary-dark md:pt-0 md:text-xl 2xl:text-2xl">
						And benefiting Washington youth, from Lake Washington to the Puget
						Sound
					</p>
				</div>
				<div className="grid gap-10 pt-10 md:grid-cols-2 md:gap-10 lg:gap-16 lg:pt-12 2xl:gap-20 2xl:pt-14 3xl:gap-28 3xl:pt-16">
					<figure className="aspect-square w-full overflow-clip rounded-[10vmin] md:aspect-[8/12] 2xl:aspect-[675/800]">
						<img
							className="h-full w-full object-cover"
							src="https://images.pexels.com/photos/23512263/pexels-photo-23512263/free-photo-of-street-musician-playing-saxophone-by-thames-in-london.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
							alt=""
						/>
					</figure>
					<div className="pt-4 md:pt-12">
						<ul className="space-y-4 lg:space-y-10">
							{benefits.map((b, idx) => (
								<li
									key={idx}
									className="flex gap-1 text-base md:gap-2 lg:text-lg 2xl:text-2xl 3xl:text-3xl"
								>
									<Icon
										name="bars"
										className="mt-1.5 size-3 flex-shrink-0 self-start text-primary lg:mt-2 lg:size-5"
									/>
									<p>{b}</p>
								</li>
							))}
						</ul>
						<div className="pt-10 text-center lg:pt-20">
							<Button asChild variant="cta">
								<Link
									to="/our-mission"
									className="w-2/3 text-base lg:text-lg 2xl:text-2xl 3xl:text-3xl"
								>
									learn more here
								</Link>
							</Button>
							<p className="text-light pt-4 text-sm lg:text-base 2xl:text-xl 3xl:text-2xl">
								How Tremolo supports you, and your community.
							</p>
						</div>
					</div>
				</div>
			</section>

			<FooterSignup />
		</main>
	)
}
